<template>
  <div id="formulario-edit-info">   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row mb-4">
            <div class="vx-col md:w-1/2 w-full">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success">Gravar</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable" color="danger">Excluir</vs-button>            
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
            </div>
        </div>
         <div class="vx-row">
            <div class="vx-col w-1/2"><label class="vs-input--label">Cliente</label></div>     
        </div>
        <div class="vx-row">            
            <div class="vx-col w-full flex">
                <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    placeholder="Selecione o cliente"
                    :reduce="c => c.id"
                    :filter="pesquisarCliente"
                    v-model="data_local.idCliente"                          
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        {{ option.nome }}
                    </template>
                </v-select>  
            </div>       
        </div>
        <div class="vx-row mt-2">
            <div class="vx-col w-1/2"><label class="vs-input--label">Tipo Teclado</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2 flex">
                <v-select                                               
                    :options="CONSTANTE_TIPOS_TECLADOS" 
                    v-model="data_local.tipoTeclado"                      
                    :reduce="m => m.data"    
                    class="w-full vs-input--label">
                </v-select> 
            </div>
            <div class="vx-col w-1/2 flex" v-if="!isDeletable">
                <vs-button size="small" @click="criarMensagensPadrao" color="primary">Criar mensagens padrão</vs-button>
            </div>   
        </div>
        <div class="vx-row mt-2">
            <div class="vx-col w-1/3"><label class="vs-input--label">Código</label></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label">Descrição</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/3 flex">
                <vs-input class="w-full" v-model="data_local.codigo" :maxlength="255"/>   
            </div>     
            <div class="vx-col w-1/3 flex">                
                <vs-input class="w-full" v-model="data_local.descricao" />
            </div>              
        </div>  

        <div class="vx-row mt-2">
            <div class="vx-col w-1/3"><label class="vs-input--label">É início de Jornada ?</label></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label">É fim de Jornada ?</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/3 flex">
                <vs-checkbox v-model="data_local.isInicioJornada" @input="desmarcarFim"></vs-checkbox>   
            </div>     
            <div class="vx-col w-1/3 flex">                
                <vs-checkbox v-model="data_local.isFimJornada" @input="desmarcarInicio"></vs-checkbox>   
            </div>              
        </div>  

        <div class="vx-row mt-2">
            <div class="vx-col w-1/3"><label class="vs-input--label">Gerar Alerta ?</label></div>     
            <div class="vx-col w-1/3" v-if="data_local.isGerarAlerta"><label class="vs-input--label">Titulo do Alerta</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/3 flex">
                <vs-checkbox v-model="data_local.isGerarAlerta"></vs-checkbox>   
            </div>     
            <div class="vx-col w-1/3 flex" v-if="data_local.isGerarAlerta">                
                <vs-input class="w-full" v-model="data_local.tituloAlerta" maxlength="50"/>
            </div>
        </div>

        
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"
import { CONSTANTE_TIPOS_TECLADOS } from '@/constantesComboBox.js'

const URL_SERVLET = "/TipoMensagemTecladoRest";
const NAME_ROUTE_SEARCH = "cadastro-tipo-mensagem-teclado-pesquisar";

export default {     
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return {codigo: null, descricao: null, tipoTeclado: null, idCliente: null, isInicioJornada: false, isFimJornada: false, isGerarAlerta: false, tituloAlerta: null}
       }
    },
  },
  data() {
    return {      
        listaClientes : [],     
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),           
    }
  },  
  created(){
      this.CONSTANTE_TIPOS_TECLADOS = CONSTANTE_TIPOS_TECLADOS;
  }, 
  mounted() {
      this.fetchListaClientes();
  },
  computed: {        
    isDeletable() {
       return this.data_local != null && this.data_local.hasOwnProperty("id");
    },    
  },
  methods: {  
    desmarcarFim() {
        if (this.data_local.isInicioJornada) {
            this.data_local.isFimJornada = false;
        }
    },
    desmarcarInicio() {
        if (this.data_local.isFimJornada) {
            this.data_local.isInicioJornada = false;
        }
    },
    criarMensagensPadrao() {
        if (this.formularioValidoMensagemPadrao()) {
            this.$vs.dialog({
            type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja criar as mensagens padrão ?', acceptText: 'Sim', cancelText: 'Não', accept: this.criarMensagensPadraoRequest
            });
        }
    },
    criarMensagensPadraoRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'CRIAR_TIPO_MENSAGEM_PADRAO');      
        params.append('tipoTeclado', this.data_local.tipoTeclado);      
        params.append('idCliente', this.data_local.idCliente);   
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('id', this.data_local.id);   
        params.append('idCliente', this.data_local.idCliente);   
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            
            if (this.registroOriginal.hasOwnProperty("id")) {
                params.append('method', 'Update');      
                params.append('id', this.data_local.id);      
            }  else {
                params.append('method', 'Insert');      
            }  

            params.append('codigo', this.data_local.codigo);      
            params.append('descricao', this.data_local.descricao);      
            params.append('tipoTeclado', this.data_local.tipoTeclado);      
            params.append('idCliente', this.data_local.idCliente);      
            params.append('isInicioJornada', this.data_local.isInicioJornada);      
            params.append('isFimJornada', this.data_local.isFimJornada);      
            params.append('isGerarAlerta', this.data_local.isGerarAlerta);    

            if (this.data_local.isGerarAlerta) {
                params.append('tituloAlerta', this.data_local.tituloAlerta);    
            }
            
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {                    
                    if (response.data.hasOwnProperty("error")) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch(() => { 
                this.$vs.loading.close();                
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];
      
        if (!this.data_local.codigo) {
            this.errorsValidacao.push("Código obrigatório");
        }

        if (!this.data_local.descricao) {
            this.errorsValidacao.push("Descrição obrigatório");
        }

        if (!this.data_local.tipoTeclado) {
            this.errorsValidacao.push("Tipo Teclado obrigatório");
        }

        if (!this.data_local.idCliente) {
            this.errorsValidacao.push("Cliente obrigatório");
        }

        if (this.data_local.isGerarAlerta && !this.data_local.tituloAlerta) {
            this.errorsValidacao.push("Título do Alerta obrigatório");
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
    formularioValidoMensagemPadrao() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];
            
        if (!this.data_local.tipoTeclado) {
            this.errorsValidacao.push("Tipo Teclado obrigatório");
        }

        if (!this.data_local.idCliente) {
            this.errorsValidacao.push("Cliente obrigatório");
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
    fetchListaClientes() {     
        
        const params = new URLSearchParams();
        params.append('method', 'FindAll');
        params.append('orderDirection', 'ASC');
        params.append('outputFormat', 'JSON');
        
        this.$vs.loading() 
        axios.post("/ClientesRest", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaClientes = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    }, 
  }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
    } 
</style>